<template>
  <div class="broker auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="ml-4">
          <b-img
            class="logo-size"
            src="~@/assets/images/logo/broker-logo.png"
            alt="Divvy" />
        </h2>
      </b-link>

      <!-- Forgot password-->
      <b-col lg="6" class="recover-password">
        <h2 class="recover-password__title">Solicitação enviada com sucesso!</h2>
        <p class="recover-password__paragraph">
          Em alguns minutos você receberá um e-mail com link para resetar sua senha.
        </p>

        <!-- form -->
        <div id="btt">
          <b-button
            class="recover-password__button"
            type="submit"
            variant="primary"
            block
            @click="$router.push({ name: 'broker-login' })">
            <feather-icon icon="CheckIcon" />
          </b-button>
        </div>

        <p class="text-center mt-2">
          <b-link
            :to="{ name: 'broker-login' }"
            class="back">
            Voltar
          </b-link>
        </p>
      </b-col>

      <b-col g="6" md="6" class="register-background">
        <div class="register-background-text">
          <h1 class="register-background-text__title">
            Compartilhe imóveis e ganhe comissões rápidas e fáceis
            <span class="register-background-text__title--broker">!</span>
          </h1>
          <p class="register-background-text__paragraph">
            Simples, fácil e seguro para qualquer pessoa investir no mercado
            imobiliário.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      emailAddress: '',
      required,
      email,
      reset: {
        emailAddress: null,
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.recover()
        }
      })
    },
    async recover() {
      try {
        const response = await this.$http.post(
          '/api/services/app/Account/SendPasswordResetCode',
          this.reset, {
            timeout: 30000,
          },
        )
        if (response) {
          this.$router.push('/recuperar-senha-solicitacao')
        }
      } catch (error) {
        this.errors = error.message
      }
    },
  },
}
</script>

<style lang="scss">
body {
  background-color: #fff !important;
}
a.back {
  color: #000;
  font-weight: 500;
}
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '../sass/_recover-password.scss';

@import '../sass/variables.scss';
@import '../sass/divvy-form.scss';
@import '../sass/register-background-text.scss';
@import '../sass/broker.scss';

.register-background {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_broker.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none !important;
}
.button-center-position {
  position: absolute;
  margin-top: 85px !important;
  left: 47.3%;
}
.input-group {
  &:not(.bootstrap-touchspin):focus-within {
    .color-input-group > div.input-group-text {
      border-color: #bc7df0 !important;
    }
  }
}

.logo-size {
  width: 40px;
}
</style>
